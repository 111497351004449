import React from 'react';
import './hero.css';
import logo from '../../assets/logo.svg';
import backgroundImage from '../../assets/hero_bg.jpg';
import heroImage from '../../assets/hero_john_wick_meme_generator.png'; 
import { Link } from 'react-scroll';

function Hero() {
  return (
    <div className="hero" style={{backgroundImage: `url(${backgroundImage})`}}>
      <nav>
        <img src={logo} alt="Logo" className="sandoval__logo"/>
      </nav>
      <div className="hero__content-container">
        <h1>Boost Your Marketing Impact with Our Unique Meme and E-card Generator</h1>

        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          >
        <button href="#contact" className="primary__btn">Get Your Custom Quote Today</button>
        </Link>
      </div>
      <div className="hero__img">
      <img className="" src={heroImage} alt="" />
      </div>
    </div>
  );
}

export default Hero;
