import React from 'react'
import './quote.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import slide1 from '../../assets/Power Rangers.jpg';
import slide2 from '../../assets/Uncle Drew.jpg';
import slide3 from '../../assets/American Underdog.jpg';
import slide4 from '../../assets/JW4.jpg';
import slide5 from '../../assets/Spy Who Dumped Me.jpg';

const bgImage = [slide1, slide2, slide3, slide4, slide5];

const Quote = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500, 
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };

      return (
        <div className="slideshow">
            <div className='line'></div>
          <Slider {...settings}>
            {bgImage.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index}`} />
              </div>
            ))}
          </Slider>
          <div className='lineBottom'></div>
          <div className="quote">
            <h2 className='small'>“Working with Sandoval was very seamless. They made everything 
  easy and work at lightning speed. The end result was exactly what 
  was needed and the fans loved it!”</h2>
            <div className="text-container">
             <hr className="horizontal-line" />
             <div className="text-left">
                <p>LIONSGATE</p>
                <p>Eric Dachman | Director, Digital Marketing</p>
            </div>
          </div>
          </div>
        </div>
      )
    }    

export default Quote