import React from 'react'
import './logos.css';
import logo1 from '../../assets/Lionsgate.svg';
import logo2 from '../../assets/kingdom_logo 1.svg';
import logo3 from '../../assets/vida_logo 1.svg';
import logo4 from '../../assets/harperlogo.svg';
import collage from '../../assets/Generator Cards.png';
import rightTri from '../../assets/righttri.svg';



const Logos = () => {
  return (
    <div>
        <section className='logo__section-container'>
        <p className='logo_p'>Trusted by Hollywood film studios and Diverse Creative Projects Worldwide.</p>
        <div className='logo__section'>
            <img src={logo1} alt="Lionsgate Logo" className="logo"/>
            <img src={logo2} alt="Kingdom Logo" className="logo"/>
            <img src={logo3} alt="Vida Logo" className="logo"/>
            <img src={logo4} alt="Harper Logo" className="logo"/>
        </div>
        <hr className='logo__hr'></hr>
        </section>
        <section className='preview__top-container'>
            <img className="rightTri" src={rightTri} alt="decoration"/>
            <div>
                <article>
                    <h2>Elevate Your Marketing with Our Blockbuster-Approved Meme & E-card Generators</h2>
                    <p>Our Meme and E-card Generators are dynamic marketing tools that have powered viral campaigns for blockbuster films like John Wick. It empowers you to craft captivating and shareable content for your film or project. Regardless of the size or scope of your project, our generators are your secret weapon to engage your audience and amplify your promotional campaigns.</p>
                </article>
                <figure>
                    <img src={collage} alt="Sandoval Agency Meme Generator for Films" />
                </figure>
            </div>
        </section>
    </div>
  )
}

export default Logos