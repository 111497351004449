import React from 'react';
import './cards.css';

import Tailor from '../../assets/tailer_icon.svg';
import Share from '../../assets/share_icon.svg';
import Viral from '../../assets/Megaphone_Vector.svg';


const Cards = () => {

    const cardContent = [
        {
          icon: Tailor,
          headline: '1. Tailored to Your Unique Campaign Needs',
          text: 'We collaborate with you to conceptualize out-of-the-box ideas, crafting a unique feature set for your customized app. The user\'s journey could range from a photo booth-style generator to a unique question-and-answer journey that generates distinctive, shareable content. This level of customization ensures each piece of content is perfectly aligned with your campaigns goals.'
        },
        {
          icon: Share,
          headline: '2. Share Directly to Social Media',
          text: 'This is Leverage the power of social media with our Meme and E-card Generators. With the ability to share content directly to social platforms, you can tap into fan virality and engagement, creating a digital campaign that truly goes viral. text for card 2.'
        },
        {
          icon: Viral,
          headline: '3. Create Viral Digital Campaigns',
          text: 'This is Our Meme and E-card Generator isn\'t just a tool—it\'s a catalyst for viral digital campaigns. By harnessing the power of user engagement and social sharing, you can create a buzz that extends far beyond your initial audience text for card 3.'
        },
      ];

  return (
    <div className="card-section">
    {cardContent.map((card, index) => (
      <div key={index} className="card">
        <img src={card.icon} alt={card.headline} />
        <h2>{card.headline}</h2>
        <p>{card.text}</p>
      </div>
    ))}
  </div>
);
};


export default Cards
