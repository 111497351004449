import React from 'react';
import saveAs from 'file-saver';
import './finalStep.css';
import { Link } from 'react-scroll';
import Refresh from '../../assets/Refresh Icon.svg'

const FinalStep = ({ finalImage, onhandleBackClick }) => {
  const handleDownload = () => {
    const blob = dataURLtoBlob(finalImage);
    const fileToSave = new File([blob], 'customized_card.png');
    saveAs(fileToSave);
  };

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  return (
    <div className="final__step-container fadeIn">
      <p>Your customized card is ready!<br></br>Download it now or customize another one.</p>
      <div className="final__image">
        <img src={finalImage} alt="Customized Card" />
      </div>
      <button className="backbtn" onClick={onhandleBackClick}><img className="chooseanother" src={Refresh} alt="icon"/>Customize another card</button>
      <button className="btn" onClick={handleDownload}>
        Download
      </button>

      <div>
        <h4>Like what you see?</h4>
        <p>Get in touch with us today and let’s take your project or idea to the next level.</p>

        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          >
        <button href="#contact" className="primary__btn-red">
        Contact Us
        </button>
        </Link>
      </div>
    </div>
  );
};

export default FinalStep;
