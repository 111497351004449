import React from 'react'
import './footer.css';

const Footer = () => {
  return (
    <footer>
        <p>© 2023 Sandoval Agency | All rights reserved.</p>
    </footer>
  )
}

export default Footer