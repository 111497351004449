import './App.css';
import React from 'react'
import { Logos, Generator, Cards, Hero, Quote, Carousel, Form, Footer, TestVideo } from './containers';


const App = () => {
  return (
    <div>
      <Hero />
      <Logos />
      <Generator />
      <Cards />
      <Quote />
      <Carousel />
      <Form />
      <Footer />
    </div>
  )
}

export default App