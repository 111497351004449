import React, { useState } from 'react';
import './generator.css'; 
import MemeGenerator from '../memeGenerator/MemeGenerator';
import Poly from '../../assets/leftpoly.png'

const Generator = () => {
  const [showMemeGenerator, setShowMemeGenerator] = useState(false);

  const handleGetStartedClick = () => {
    setShowMemeGenerator(true);
  };

  return (
    <div className='generator__container'>
      <img className="poly" src={Poly} alt="decoration"/>
      <div className='app__wrap'>
      <h2>Try it for yourself</h2>
      <div className='app__box'>
      {!showMemeGenerator && (
        <div className='first__view'>
        
        <div className='generator_start'>
            <p>
            Dive into our interactive demo and create your own viral content in minutes. Experiment with photo uploads, text overlays, and a few frames to craft your unique message. When we add our social sharing features your audience will have a blast. Imagine the possibilities, envision the impact, and let's build something awesome for your next campaign.
            </p>
        </div>
        <button className='secondary__btn'  onClick={handleGetStartedClick}>Get Started</button>
        </div>
      )}
        {showMemeGenerator && <MemeGenerator className="fadeIn"/>}
        </div>
        </div>
    </div>
  )
}

export default Generator