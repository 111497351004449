import React, { useState, useRef } from 'react';
import './form.css';
import bgDecor from '../../assets/redfooterbg.svg';
import bgDecorBlack from '../../assets/squiggle.svg';
import bgForm from '../../assets/form_bg.png';
import iphone from '../../assets/iPhones.png';
import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('Submit');

    const recaptchaRef = useRef();


    const apiKey = process.env.REACT_APP_MAILGUN_API_KEY;
    const domain = process.env.REACT_APP_MAILGUN_DOMAIN;
    const recipientEmail = process.env.REACT_APP_RECIPIENT_EMAIL;

  

    const handleFormReset = () => {
        setName('');
        setEmail('');
        setMessage('');
      };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const recaptchaValue = recaptchaRef.current.getValue();
      if (!recaptchaValue) {
        console.log("Please complete the reCAPTCHA challenge");
        return;
      }
  
      setStatus('Sending...');
  
      try {
        const formData = `recaptcha=${recaptchaValue}&from=${email}&to=${recipientEmail}&subject=MEME Contact Form Submission&text=${message}`;
        const response = await fetch(`https://api.mailgun.net/v3/${domain}/messages`,  {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic ' + btoa(`api:${apiKey}`)
          },
          body: `from=${email}&to=${recipientEmail}&subject=MEME Contact Form Submission&text=${message}`
        });
  
        if (response.ok) {
          setStatus('Sent');
          handleFormReset();
        } else {
          setStatus('Failed');
        }
      } catch (error) {
        console.error('Error sending the form:', error);
        setStatus('Failed');
      }
    };
  
    const buttonLabel = status === 'Sending...' ? status : 'Send';

  return (
    <div>
        <section id="contact" className='form__section' >
            <div className='form__container' style={{backgroundImage: `url(${bgForm})`, backgroundRepeat: 'no-repeat'}}>
                <span>Ready to Amplify Your <br></br>Marketing Efforts?</span>
                <p>Get started with our Meme and E-card Generator today and take your marketing to the next level. Fill out the form below and we'll get back to you with a custom quote.</p>

                <div>
                <form onSubmit={handleSubmit}>
                    <div className='form-row'>
                    <div className="form-field">
                    <label>Your name*</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Name'/>
                    </div>
                    <div className="form-field">
                    <label>Email*</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                    </div>
                    </div>
                    <div className="form-field">
                    <label>Tell us about your project*</label>
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Tell us about your project' />
                     </div>
                     <div className="form-field">
                        
                        <ReCAPTCHA
                        className='captcha'
                        ref={recaptchaRef}
                        sitekey="6LdLWdEnAAAAAArnohrGagAI7y24Xh880GZmCwi7"
                        onChange={(response) => console.log(response)}
                        />
                    </div>
                    <button type="submit">{buttonLabel}</button>
                </form>
                </div>
            </div>
            <div className='phone-container'>
            <img className="iphones" src={iphone} alt="decoration"/>
            </div>
            <img className="bgDecor" src={bgDecor} alt="decoration"/>  
            <img className="bgDecorBlack" src={bgDecorBlack} alt="decoration"/>  
        </section>

    </div>
  )
}

export default Form