import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image, Text } from 'react-konva';
import saveAs from 'file-saver';
import Webcam from 'react-webcam';
import { useImage } from 'react-konva-utils';  
import CardSelection from '../cardSelection/CardSelection';
import FinalStep from '../finalStep/FinalStep';
import './memegenerator.css';
import photo from '../../assets/photo.svg';
import retake from '../../assets/retake.svg';
import upload from '../../assets/upload.svg';
import Konva from 'konva';
import {swears} from '../data.js'
import LeftSmall from '../../assets/leftsmallarrow.svg';

const MemeGenerator = () => {
    const [currentCard, setCurrentCard] = useState(null);
    const [currentCardImage, setCurrentCardImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [webcamActive, setWebcamActive] = useState(true);
    const [scale, setScale] = useState(1);
    const imageNodeRef = useRef(null);
    const stageRef = useRef(null);
    const webcamRef = useRef(null);
    const uploadInputRef = useRef(null);
    const stageWidth = 372;
    const stageHeight = 372;
    const [video, setVideo] = useState(null);
    const layerRef = useRef(null);
    const animRef = useRef(null);

    // text
    const [text, setText] = useState('');
    const [counter, setCounter] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [dragging, setDragging] = useState(false);


    const handleChange = (e) => {
      setText(e.target.value);
      setCounter(e.target.value.length);
    };

    const handleMouseDown = (e) => {
      console.log("Mouse or touch down");
      console.log("Touch or Mouse Move Coordinates:", e.clientX, e.clientY);
      setDragging(true);
      setX(e.clientX);
      setY(e.clientY);
    }

    const handleMouseMove = (e) => {
      console.log("Mouse or touch move");
      console.log("Touch or Mouse Move Coordinates:", e.clientX, e.clientY);
      const nativeEvt = e.evt;
      console.log("Native event:", nativeEvt);
        if (dragging) {
            setX(e.clientX);
            setY(e.clientY);
        }
    }

    const handleMouseUp = (e) => {
      console.log("Mouse or touch up");
        setDragging(false);
    }


    const handleParentTouchMove = (e) => {
      console.log("Parent touchmove event triggered");
      e.stopPropagation();
    };

    useEffect(() => {
      const stage = stageRef.current;
      if (stage) {
        const container = stage.container();
        container.addEventListener("touchmove", handleParentTouchMove);
    
        return () => {
          container.removeEventListener("touchmove", handleParentTouchMove);
        };
      }
    }, [stageRef.current]);

    const maxTextX = stageWidth - 20; // 20 pixels offset from the right
    const maxTextY = stageHeight - 20; // 20 pixels offset from the bottom


    //Select & Display card in preview
    const [image] = useImage(localStorage.getItem("currentImage"));
    const [showCardsRow, setShowCardsRow] = useState(true);
    const [showFinalStep, setShowFinalStep] = useState(false);

    const [finalImage, setFinalImage] = useState(null);

    // show cam
    const [showWebcam, setShowWebcam] = useState(false);
    const handleShowWebcam = () => {
        setShowWebcam(true);
    };

    const [hasCapturedPhoto, setHasCapturedPhoto] = useState(false);

    useEffect(() => {
        if (currentCard) {
            handleShowWebcam();
            const cardImage = new window.Image(); 
            cardImage.src = currentCard;
            cardImage.onload = () => {
                const imageScaleX = stageWidth / cardImage.width;
                const imageScaleY = stageHeight / cardImage.height;
                setScale(Math.min(imageScaleX, imageScaleY));
                setCurrentCardImage(cardImage);
            };
        }
    }, [currentCard]);

    const handleCardSelection = (card) => {
        setCurrentCard(card);
        // console.log("Card selected:", card);
    };

    // upload and take a photo
    const capturePhoto = () => {
      if (webcamRef.current) {
          const imageSrc = webcamRef.current.getScreenshot();
          const capturedImage = new window.Image();
          capturedImage.onload = () => {
              setUploadedImage(capturedImage);
              setHasCapturedPhoto(true);
              // Deactivate the webcam after capturing the image
              setWebcamActive(false);
          };
          capturedImage.src = imageSrc;
      }
  };

      const resizeImage = (image, width, height) => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg");
    };

    const retakePhoto = () => {
        setHasCapturedPhoto(false); // Reset the captured photo state
        setWebcamActive(true); // Activate the webcam again
    };

    
    // upload photo
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uploadedImg = new window.Image();
          uploadedImg.src = event.target.result;
          setUploadedImage(uploadedImg);
          setHasCapturedPhoto(true); // Set hasCapturedPhoto to true
          setWebcamActive(false); // Deactivate the webcam after uploading
        };
        reader.readAsDataURL(file);
      }
    };
    
    const promptImageUpload = () => {
        uploadInputRef.current.click();
    };
    

    const handleCreate = () => {
      const capturedImage = uploadedImage; // Use the capturedImage state
      if (capturedImage) {

        let foundSwears = [];
        for (let i = 0; i < swears.length; i++) {
          let swear = swears[i];
          let regex = new RegExp(`\\b${swear.toLowerCase()}\\b`);
          if (regex.test(text.toLowerCase())) {
            foundSwears.push(swear);
          }
        }
    
        if (foundSwears.length > 0) {
          alert('Please remove profanity from your message.');
          return; // Don't proceed with creating the meme
        }




          const resizedImage = new window.Image();
          resizedImage.onload = () => {
              const uri = stageRef.current.toDataURL({ pixelRatio: window.devicePixelRatio });
              setFinalImage(uri); // Use resizedImage instead of uri
              setShowFinalStep(true);
          };
          resizedImage.src = resizeImage(capturedImage, stageWidth, stageHeight);
      }
  };

  const [webcamLoading, setWebcamLoading] = useState(true);


  useEffect(() => {
    if (webcamActive && webcamRef.current) {
      setWebcamLoading(true); // Set webcamLoading to true when webcam starts loading
      const video = document.createElement('video');
      video.srcObject = webcamRef.current.stream;
      video.play();
      setVideo(video);
  
      animRef.current = new Konva.Animation(() => {
        // do nothing, animation just need to update the layer
      }, layerRef.current);
      animRef.current.start();
      
      // Set webcamLoading to false when webcam is done loading
      video.addEventListener('loadeddata', () => {
        setWebcamLoading(false);
      });
    } else {
      if (animRef.current) {
        animRef.current.stop();
      }
      setVideo(null);
    }
  }, [webcamActive, webcamRef.current]);
  

  function handleBackClick() {
    setCurrentCard(null); // Reset the currentCard state to null
    setShowFinalStep(false); // Hide the final step view
    setHasCapturedPhoto(false); // Reset the hasCapturedPhoto state
    setShowWebcam(false); // Hide the webcam view
    setShowCardsRow(true);
  }


    return (
        <div className="memegenerator__container">
            {showFinalStep ? (
                <FinalStep finalImage={finalImage}
                onhandleBackClick={handleBackClick} />

            ) : (
          showCardsRow ? (
            <CardSelection
              onCardSelect={handleCardSelection}
              showCardsRow={showCardsRow}
              setShowCardsRow={setShowCardsRow}
              className='fadeIn'
            />
          ) : (
            <div className="preview-wrapper">
              {!hasCapturedPhoto && (
                <Webcam
                  className='webcam-square fadeIn'
                  audio={false}
                  height={stageHeight}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={stageWidth}
                  videoConstraints={{ facingMode: 'user',
                  aspectRatio: 1,
                 }}
                  screenshotQuality={0.8}
                  
                />
              )}
    
              {hasCapturedPhoto && (
                <div className='stage__container'>
               <Stage
               className='stage'
               width={stageWidth}
               height={stageHeight}
               ref={stageRef}
               pixelRatio={window.devicePixelRatio}
               
             >
               {/*Image and Card Layer*/}
               <Layer ref={layerRef}>
               {video && (
                    <Image
                        image={video}
                        width={stageWidth}
                        height={stageHeight}
                    />
                )}
                 {uploadedImage && (
                   <Image
                     image={uploadedImage}
                     width={stageWidth}
                     height={stageHeight}
                   />
                 )}
             
                 {/* This is the card overlay */}
                 <Image
                   ref={imageNodeRef}
                   image={image || currentCardImage}
                   width={(image || currentCardImage).width * scale}
                   height={(image || currentCardImage).height * scale}
                 />


                 {text && (
                  <Text
                    text={text.toUpperCase()} 
                    x={50}
                    y={150}
                    fontSize={20}
                    draggable={true} 
                    fill="white"
                    fontFamily="gigalypse, sans-serif"
                    fontWeight={800}
                    align="center" 
                    verticalAlign="bottom" 
                    width="270" 
                    lineHeight="1.2" 
                    fontStyle="bold"
                    shadowColor='grey'
                    shadowBlur={4} 
                    shadowOffsetX={0} 
                    shadowOffsetY={4} 
                    shadowOpacity={0.5} 
                    wrap="wrap" 

                    stroke="transparent"
                    strokeWidth={4}

                    hitFunc={function(context) {
                        context.beginPath();
                        context.rect(0, -30, 200, 50);  // Define the hit area as a rectangle
                        context.closePath();
                        context.fillStrokeShape(this);  // 'this' now refers to the Text shape
                      }}

                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    
                    onTouchStart={(e) => {
                      e.evt.preventDefault(); // Prevent default to stop scrolling while dragging
                      handleMouseDown(e.evt);
                    }}
                    onTouchMove={(e) => {
                      e.evt.preventDefault(); 
                      handleMouseMove(e.evt);
                    }}
                    onTouchEnd={(e) => {
                      e.evt.preventDefault(); 
                      handleMouseUp(e.evt);
                    }}

                    onMouseEnter={e => {
                      const container = e.target.getStage().container();
                      container.style.cursor = "pointer";
                    }}
                    onMouseLeave={e => {
                      const container = e.target.getStage().container();
                      container.style.cursor = "default";
                    }}
                    
                    onDragMove={(e) => {
                      const newTextX = Math.min(Math.max(e.target.x(), 0), stageWidth - e.target.width() - 20);
                      setX(newTextX);
                      setY(e.target.y());
                    //   console.log(`Text dragged to x: ${newTextX}, y: ${e.target.y()}`);
                    }}


                  />


                 )}


               </Layer>
             
     
             </Stage>
             </div>
              )}
    
    {hasCapturedPhoto && (
              <div className="form-section fadeIn">
                <button className="backbtn" onClick={handleBackClick}><img className="chooseanother" src={LeftSmall} alt="icon"/>Choose A Different Card</button>
                <p>Customize your selected card template by adding a personalized message and dragging in place.</p>
                <textarea
                  className='text__input'
                  style={{
                    textAlign: '',
                    paddingLeft: '8px',
                  }}
                  type="text"
                  placeholder="Write your message here up to 50 characters..."
                  value={text}
                  onChange={handleChange}
                  maxLength={50}
                />
                <div>Characters: {counter} / 50</div>
                <button className='btn' onClick={handleCreate}>Create</button>

              </div>
            )}

            {!hasCapturedPhoto && (
              <div className='image__btns-container'>
                 <p>Take or upload a photo.</p>
                   <div className="image-buttons">
                <img
                  src={photo}
                  alt="Take Photo"
                  className="btn-image"
                  onClick={capturePhoto}
                />
                <label htmlFor="fileInput">
                  <img
                    src={upload}
                    alt="Upload"
                    className="btn-image"
                  />
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={uploadInputRef}
                  onChange={handleImageUpload}
                />
              </div>
              </div>
            )}

          </div>
        )
      )}
    </div>
  );
};

export default MemeGenerator;
