import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import './carousel.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import jw4 from '../../assets/jw4Carousel.png';
import inq from '../../assets/inquentCarousel.png';
import robot from '../../assets/robotcarousel.png';
import early from '../../assets/earlycarousel.png';

const Carousel = () => {
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    afterChange: current => setActiveIndex(current)
  };

  const images = [jw4, inq, robot, early];
  const titles = ["John Wick Chapter 4", "Inquebrantables (Un-breakables)", "Robot Feelings", "Early Man"];


  const goToSlide = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-slider">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <nav className="carousel-nav">
        <div className='caroursel-text'>
        <h4>Campaigns</h4>
        <p>Take a look at some of the projects we’re honored to partner with and be a part of.</p>    
        </div>
        <ul>
            {titles.map((title, index) => (
       
                <li key={index} className={`nav-item ${index === activeIndex ? 'active' : ''}`} onClick={() => goToSlide(index)}>
                    {title}
                </li>
        
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Carousel;
