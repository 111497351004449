import React, { useState, useEffect } from 'react';
import './cardSelection.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Card1 from '../../assets/Movie.png';
import Card2 from '../../assets/Holiday.png';
import Card3 from '../../assets/Sports.png';
import Left from '../../assets/Left Arrow.svg';
import Right from '../../assets/Right Arrow.svg';






const CardSelection = ({ onCardSelect, showCardsRow, setShowCardsRow }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (card) => {
    setSelectedCard(card);
    onCardSelect(card);
  };

  const handleCustomizeClick = () => {
    setShowCardsRow(false);
  };


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id="arrow"
        className="slick-arrow"
        style={{ ...style, display: 'block', position: 'absolute', right: 5, top: 135, cursor: 'pointer',}}
        onClick={onClick}
      >
      <img src={Right} alt="Arrow Right"/>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id="arrowR"
        className="slick-arrowR"
        style={{ ...style, display: 'block', position: 'absolute', zIndex: 1, top: 135, left: 5, cursor: 'pointer',}}
        onClick={onClick}
      >
       <img src={Left} alt="Arrow Left"/>
       </div>
    );
  }
  



  if (isMobile) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "0px",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      <div className='card__select-container'>
        <p>Select a preferred card from the options below:</p>
        <div className="cards__slider">
          <Slider {...settings}>
            <div className="cards__slide">
              <div id="frames" className="cards__card cards__card-slider" onClick={() => handleCardClick(Card1)}>
                <img className="frameDesk" src={Card1} alt="Movie Card" />
              </div>
            </div>
            <div className="cards__slide">
              <div id="frames" className="cards__card cards__card-slider" onClick={() => handleCardClick(Card2)}>
                <img className="frameDesk" src={Card2} alt="Holiday Card" />
              </div>
            </div>
            <div className="cards__slide">
              <div id="frames" className="cards__card cards__card-slider" onClick={() => handleCardClick(Card3)}>
                <img className="frameDesk" src={Card3} alt="Sports Card" />
              </div>
            </div>
          </Slider>
        </div>
        {selectedCard && (
          <button className='btn' onClick={handleCustomizeClick}>
            Customize
          </button>
        )}
      </div>
    );
  }

  return (
    <div className='card__select-container'>
      <p>Select a preferred card from the options below:</p>
      <div className="cards__row">
  <div
    className={`cards__card ${selectedCard === Card1 ? 'active' : ''}`}
    onClick={() => handleCardClick(Card1)}
  >
    <img className="frameDesk" src={Card1} alt="Movie Card" />
  </div>
  <div
    className={`cards__card ${selectedCard === Card2 ? 'active' : ''}`}
    onClick={() => handleCardClick(Card2)}
  >
    <img className="frameDesk" src={Card2} alt="Holiday Card" />
  </div>
  <div
    className={`cards__card ${selectedCard === Card3 ? 'active' : ''}`}
    onClick={() => handleCardClick(Card3)}
  >
    <img className="frameDesk" src={Card3} alt="Sports Card" />
  </div>
</div>

      {selectedCard && (
        <button className='btn' onClick={handleCustomizeClick}>
          Customize
        </button>
      )}
    </div>
  );
}

export default CardSelection;
